<template>
  <el-card class="box-card">
    <el-form ref="form" label-width="100px" :model="form">
      <el-form-item style="margin-bottom: 20px" label="模块开关" prop="switch">
        <el-radio-group v-model="form.switch" size="small" @change="submit">
          <el-radio-button label="0">暂不启用</el-radio-button>
          <el-radio-button label="1">启用</el-radio-button>
        </el-radio-group>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      form: {
        switch: '1',
      },
    }
  },
  created() {
    this.requestObtain()
  },
  methods: {
    async requestObtain() {
      const { data } = await getExam()
      this.form.switch = data.switch
    },
    async submit() {
      const { code, message } = await Cac(this.form)
      if (code == '200') {
        this.$message.success(message)
        this.$emit('fetch-data')
        this.close()
      } else {
        this.$message.error(message)
        this.$emit('fetch-data')
        this.close()
      }
    },
  }
}

</script>
<style>
  .el-pagination {
    text-align: center;
    margin-top: 20px;
  }

  .el-form-item {
    margin-bottom: 0;
  }

  .el-dropdown {
    margin-right: 10px;
  }

  .page {
    background-color: #FFF;
    padding: 20px;
    margin: 20px;
  }

  .el-row {
    margin-bottom: 15px;
  }
</style>
